import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { NavController } from '@ionic/angular';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
type AOA = [];


@Component({
  selector: 'app-mass-import',
  templateUrl: './mass-import.component.html',
  styleUrls: ['./mass-import.component.scss'],
})
export class MassImportComponent implements OnInit {
  @Input() payload: any;
  @Input() name: any;
  @Input() model: any;

  userId: any = JSON.parse(localStorage.getItem('userId'));
  ComCode: any = 1000;
  eventData: any;
  usersData: any
  data: any;
  usergroup: any = [];
  arrayBuffer: any;
  count: any =0;
  fileName: any = [];
  season:any;
  fileType: any;
  fileSelected: boolean = false;
  constructor(public modalCtrl: ModalController, private commonService: CommonService,
    private navController: NavController,private router: Router,) { }

  ngOnInit() {
    this.season = '';
    this.userGroupDropdown();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  onSelectedFile(eventDataSelect: any, index:any) {
    this.eventData = eventDataSelect;
    const file = eventDataSelect.target.files[0];
    if (file) {
      this.fileName[index] = file.name;
    } else {
      this.fileName[index] = undefined;
    }
  }

  onSubmit() {
    console.log(this.payload)
    // this.count = 0;

    let fileReader = new FileReader();    
  fileReader.readAsArrayBuffer(this.eventData.target.files[0]);     
  fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
      console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
      var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      let keys = Object.keys(arraylist[0])
      console.log("keys",keys)
      let key;
      keys.forEach(element => {
        if(arraylist[0][element] == "Description")
        {
          key = element
        }
      });
      this.count = 0;
      for(let i=1;i<arraylist.length;i++)
        {
            let filter = arraylist.filter((ele: any)=>ele[key]==arraylist[i][key])
            if(filter.length>1)
            {
              this.count++;
            }
        }
        let ExcelSheetdata = new FormData();
        let fileData = this.eventData.target.files[0]
        let _id = this.userId
        let ComCode = this.ComCode
        console.log("userno,comcode", _id, ComCode)
        ExcelSheetdata.append('file', fileData);
        // ExcelSheetdata.append('type', this.eventData.target.files[0].type);
        ExcelSheetdata.append('model', this.model);
    
        // let x= ExcelSheetdata.append("xlsx", fileData);
        ExcelSheetdata['file'] = fileData;
        ExcelSheetdata['id'] = this.userId;
        // ExcelSheetdata['type'] = this.eventData.target.files[0].type;
        ExcelSheetdata['model'] = this.model
        ExcelSheetdata.append('overwrite', 'false');
        ExcelSheetdata.append('id', this.userId);
        console.log("reuqests",fileData)
        // ExcelSheetdata.append('ComCode',this.ComCode);
    
    
        console.log("Sending Excel sheet data", ExcelSheetdata)
        if(this.count>0 && this.model =="material")
        {
          this.commonService.showToast("error","Duplicate Description exists")
        }
        else{
          // this.commonService.showToast("success","Duplicate Description exists")
          if(this.model == "salesOrder")
          {
            this.commonService.massUploadSales(this.payload, ExcelSheetdata)
            .subscribe((res: any) => {
              this.commonService.showToast('success', 'Uploaded Succcessfully');
              console.log("article upload Excel sheet Response", res)
              // this.myService.openSnackBar(res.msg,"Done")
              this.modalCtrl.dismiss({key: 'Success'});
              // console.log("article upload Excel sheet Response", res)
              // console.log("article upload Excel sheet Response msg", res.msg)
      
            }, (error: any) => {
              console.log("Error comming in article excel sheet", error)
              // console.log("Error comming in article excel sheet", error.text)
            });
          }
          else{
            this.commonService.massUpload(this.payload, ExcelSheetdata)
            .subscribe((res: any) => {
              this.commonService.showToast('success', 'Uploaded Succcessfully');
              console.log("article upload Excel sheet Response", res)
              // this.myService.openSnackBar(res.msg,"Done")
              
              this.close();
              if(this.model=="salesPricing"){
                this.router.navigate(['/price-home']);
              }else{
                this.navController.pop();
              }
              
              // console.log("article upload Excel sheet Response", res)
              // console.log("article upload Excel sheet Response msg", res.msg)
      
            }, (error: any) => {
              console.log("Error comming in article excel sheet", error)
              // console.log("Error comming in article excel sheet", error.text)
            });
          }
        }
  }   
    

    //  let formData={
    //    "userId":this.userId,
    //    "ComCode":this.ComCode,
    //    "overwrite":'false',
    //    "ExcelSheetdata['name']":this.eventData.target.files[0].name,
    //   "ExcelSheetdata['type']": this.eventData.target.files[0].type,

    //   }
    // this.isLoading=true;
  
  }

  onSubmitUser() {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(this.eventData.target);
    // if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.createRequest(this.data)
    };
    reader.readAsBinaryString(target.files[0]);
  }

  createRequest(data) {
    let count = 0;
    let success = 0;
    let len =0;
    for(let i=1;i<data.length; i++){
      if(data[i].length>0)
      len++
    }
    for (let i = 1; i < data.length; i++) {
      if (data[i].length > 0) {
        count++;
        var param = {}
        for (let j = 0; j < data[0].length; j++) {
          param[data[0][j]] = data[i][j]
        }
        param['active'] = true
        console.log("param", param)
        param['organisationId'] = this.userId;
        console.log("payload is ",this.usergroup)
        let userGroup= this.usergroup.filter(res=>param['userType'] ==  res.userGroupName)[0]
        console.log("payload is ",userGroup)
        param['userGroup'] = userGroup._id
        console.log(userGroup,param)
        this.commonService.createUserRequest(param).then((resp: any) => {
          if (resp.err) {
            this.commonService.showToast('error', resp.msg+" "+resp.err.keyValue.email);
            this.close();
              // this.navController.pop();
          }
          else {
            success++;
            if(len==success)
            {
              this.commonService.showToast('success',"Users have been created");
              this.close();
              // this.navController.pop();
              if(this.model=="salesPricing"){
                this.router.navigate(['/price-home']);
              }else{
                this.navController.pop();
              }
            }
            // this.commonService.showToast('success', "User has been created");
          }
        })
      }
    }
  }
  userGroupDropdown() {
    this.commonService.fetchAllrequest({domain: 'userGroup', skip: 0}).then((resp: any) => {
      this.usergroup = resp;
      console.log("User Group", this.usergroup)
    })
  }

  selectFileType(ev:any)
  {
    console.log(this.fileType)
    if(ev.target.value!=null && ev.target.value!='')
    this.fileSelected = true;
    else
    this.fileSelected = false;
  }

  onSubmitBOM()
  {
    console.log(this.season)
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(this.eventData.target.files[0]);     
    fileReader.onload = (e) => {    
        this.arrayBuffer = fileReader.result;    
        var data = new Uint8Array(this.arrayBuffer);    
        var arr = new Array();    
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
        var bstr = arr.join("");    
        var workbook = XLSX.read(bstr, {type:"binary"});    
        var first_sheet_name = workbook.SheetNames[0];    
        var worksheet = workbook.Sheets[first_sheet_name];    
        console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
          let ExcelSheetdata = new FormData();
          let fileData = this.eventData.target.files[0]
          ExcelSheetdata.append(this.fileType, fileData);
          ExcelSheetdata.append('model', 'bomMass');
          // ExcelSheetdata.append('fileType', this.fileType);
          ExcelSheetdata.append('season', this.season);
      
          ExcelSheetdata[this.fileType] = fileData;
          ExcelSheetdata['id'] = this.userId;
          ExcelSheetdata['model'] = 'bomMass'
          // ExcelSheetdata['fileType'] = this.fileType
          ExcelSheetdata['season'] = this.season
          // ExcelSheetdata.append('overwrite', 'false');
          ExcelSheetdata.append('id', this.userId);
              this.commonService.massUploadBOM(this.payload, ExcelSheetdata)
              .subscribe((res: any) => {
                this.commonService.showToast('success', 'Uploaded Succcessfully');
                this.close();
                this.router.navigate[('bom/home-bom')]
              }, (error: any) => {
                console.log("Error comming in article excel sheet", error)
                // console.log("Error comming in article excel sheet", error.text)
              });
            }
          }
  
  onSubmitPLM(){
    console.log(this.season)
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(this.eventData.target.files[0]);     
    fileReader.onload = (e) => {    
        this.arrayBuffer = fileReader.result;    
        var data = new Uint8Array(this.arrayBuffer);    
        var arr = new Array();    
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
        var bstr = arr.join("");    
        var workbook = XLSX.read(bstr, {type:"binary"});    
        var first_sheet_name = workbook.SheetNames[0];    
        var worksheet = workbook.Sheets[first_sheet_name];    
        console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
          let ExcelSheetdata = new FormData();
          let fileData = this.eventData.target.files[0]
          ExcelSheetdata.append(this.fileType, fileData);
          ExcelSheetdata.append('model', 'bomMass');
          ExcelSheetdata.append('type', this.fileType);
          ExcelSheetdata[this.fileType] = fileData;
          ExcelSheetdata['id'] = this.userId;
          ExcelSheetdata['model'] = 'bomMass'
          ExcelSheetdata['type'] = this.fileType
          // ExcelSheetdata.append('overwrite', 'false');
          ExcelSheetdata.append('id', this.userId);
              this.commonService.massUploadPLM(this.payload, ExcelSheetdata)
              .subscribe((res: any) => {
                this.commonService.showToast('success', 'Uploaded Succcessfully');
                this.close();
                this.router.navigate[('bom/home-bom')]
              }, (error: any) => {
                console.log("Error comming in article excel sheet", error)
                // console.log("Error comming in article excel sheet", error.text)
              });
            }
  }
}
